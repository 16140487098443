@import 'variables';
*{
  margin: 0;
  padding: 0;
}
a{
  text-decoration: none;
}
body{
  margin: 0;
  padding: 0;
  background: #F1F2F3;
  font-family:$ibmplexRegular !important;
  ::-webkit-input-placeholder { /* Edge */
    font-size: 13px;
    font-family:$ibmplexRegular !important;
  }
  
  :-ms-input-placeholder { /* Internet Explorer */
    font-size: 13px;
    font-family:$ibmplexRegular !important;
  }
  
  ::placeholder {
    font-size: 13px;
    font-family:$ibmplexRegular !important;
  }
  input{
    font-size: 15px;
  }
  input[type=text]:disabled {
    cursor: not-allowed;
  }
  .invite-user {
    invite-user:link {
      color: #000000;
    }

/* visited link */
  a:visited {
  color: #000000;
  }

/* mouse over link */
  a:hover {
    color: #000000;
  }

/* selected link */
  a:active {
    color: #000000;
  }
}
  .application-card{
    background: #7D7561;
    padding: 20px;
    border-radius: 4px;
    height: 200px;
    position: relative;
    box-shadow: 0 2px 2px rgba(0,0,0,0.2);
    .application-info{
      margin-bottom: 20px;
      h3{
        margin-bottom: 20px;
        img{
          margin-right: 15px;
        }
        span{
          text-shadow: 0 2px 2px rgba(0,0,0,0.2);
          @include elementStyles($size: 20px, $color: #ffffff, $fontFamily: $ibmplexMedium);
        }
      }
      p{
        @include elementStyles($size: 16px, $color: #ffffff, $fontFamily: $ibmplexRegular);
      }
    }
    p.restrict-access{
      position: absolute;
      left: 20px;
      bottom: 20px;
      @include elementStyles($size: 13px, $color: #fff03b, $fontFamily: $ibmplexRegular);

    }
    button{
      position: absolute;
      left: 20px;
      bottom: 10px;
      @include elementStyles($size: 20px, $color: #ffffff, $fontFamily: $ibmplexMedium);
    }
  }
  .warning-info-text{
    font-size: 10px;
    width: fit-content;
    display: block;
    background: #fff1b6;
    padding: 2px 5px;
    color: #a78701;
    border: 1px solid #a78701;
    border-radius: 4px;
  }
  .error-page{
    text-align: -webkit-center;
    height: 100vh;
    h2{
      font-size: 32px;
      color: #838383;
      font-family: $ibmplexMedium;
    }
    p{
      font-size: 22px;
      color: #838383;
      font-family: $ibmplexRegular;
      margin-top: 40px;
    }
    .error-image{
      display: block;
      margin: 30px 0;
      width: 400px;
    }
    .go-back-button{
      margin-top: 20px;
      padding:10px;
      color: #fff;
      background: #007bff;
    }
  }
  .page-not-found {
		padding: 100px 200px;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		background: #fff;
		text-align: center;
		box-shadow: 0 1px 2px rgba(0,0,0,0.3);
		h1{
			font-family: $ibmplexSemiBold;
			font-size: 70px;
			color: #000;
		}
		h4{
			font-family: $ibmplexMedium;
			font-size: 30px;
			color: #000;
		}
		a{
			text-decoration: underline;
		}
	}
  .not-registered{
      @include elementStyles(13px, #000000, normal, normal, $ibmplexRegular, null, null);
      margin-top: 5px;
      a{
        text-decoration: underline;
      }
  }
  .license-modal{
    .license-title{
      color:#bd10e0;
      .MuiTypography-h6{
        font-weight: 400 !important;
      }
    }
    .licence-description{
      display: flex-centered;
      align-items: center;
      margin:40px 0px 30px 0px;
    }
    .licence-action{
      display: block;
      .licence-action-link{
        color: #fff;
        text-decoration: none;
      }
    }
  }
  .notice-info{
    font-size: 11px;
    margin-bottom: 10px;
    background: #fff1b6;
    padding: 2px 5px;
    color: #a78701;
    border-radius: 4px;
  }
  .custom-label{
    @include elementStyles($size: 16px, $color: rgba(0, 0, 0, 0.54), $fontFamily: $ibmplexRegular);
  }
  .table-responsive{
    display: block;
    width: 100%;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .error-msg{
      @include elementStyles($size: 12px, $color: #ff0000, $fontFamily: $ibmplexRegular);
  }
  .for-label{
    @include elementStyles($size: 16px, $color: rgba(0,0,0,0.54), $fontFamily: $ibmplexRegular);
    margin-bottom: 6px;
    display: inline-block;
  }
  .list-unstyled{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .list-inline-solution{
    width: 100%;
  }
  .list-inline{
    list-style: none;
    display: inline-block;
    li.list-inline-item{
      display: inline-block;
    }
  }
  .list-inline-item-solutions{
    float:right;
    margin: 15px 15px 0 0;
  }
  .list-right-item{
    @include elementStyles( 14px, #ffffff!important, normal, normal, $ibmplexSemiBold, null, null);
    float: right;
    background-color: #3f51b5;
    padding: 8px 10px;
    border-radius: 4px;
    text-transform: uppercase;
}
  .flex-centered{
    display: flex;
    align-items: center;
  }
  .flex-not-centered{
    display: flex;
    display: -webkit-flex;
    flex-flow: wrap;
    -webkit-flex-flow: wrap;
    &:before, &:after {
         width: 0;
    }
  }
  .text-center{
    text-align: center;
  }
  .text-left{
    text-align: left;
  }
  .text-right{
    text-align: right;
  }
  .page-description{
    .page-title{
      @include elementStyles($size: 18px, $color: #bd10e0, $fontFamily: $ibmplexRegular);
      margin-bottom: 4px;
    }
    .page-para{
      @include elementStyles($size: 14px, $color: #838383, $fontFamily: $ibmplexRegular);
    }
  }
  .btn{
    &.btn-primary{
      padding: 6px 24px;
      border-radius: 3px;
      cursor: pointer;
      border: 1px solid #2670ca;
      background: #2f8cff;
      @include elementStyles($size: 12px, $color: #fff, $fontFamily: $ibmplexRegular);
    }
  }
  .apps-title-cta{
    margin-bottom: 20px;
    h4{
      @include elementStyles($size: 20px, $color: $blackColor, $fontFamily: $ibmplexRegular);
      margin-bottom: 10px;
    }
    p{
      @include elementStyles($size: 14px, $color: #8b8d91, $fontFamily: $ibmplexRegular);
    }
  }
  .apps-title-cta-list{
    ul{
      float: right;
      li{
        margin-left: 15px;
        &.revision-history{
          cursor: pointer;
          @include elementStyles($size: 12px, $color: #2f8cff, $fontFamily: $ibmplexSemiBold);

        }
        &:first-child{
          margin-left: 0;
        }
      }
    }
  }
  .mui-styled-table{
    &.hovered-table{
      tr{
        background: inherit;
        cursor: pointer;
      }
    }
    .actions-cell{
      text-align: right;
      svg{
        cursor: pointer;
      }
    }
    thead{
      background: #b5bedb;
      th{
        padding: 6px 10px;
        background: #b5bedb;
        font-weight: 500;
        font-family: $ibmplexMedium;
        span{
          @include elementStyles($size: 13px, $color: rgba(0,0,0), $fontFamily: $ibmplexMedium, $weight:500);
          &.MuiTableSortLabel-root{
            &:hover{
              color: #496ac5;
              .MuiTableSortLabel-icon{
                color: #496ac5 !important;
              }
            }
            .MuiTableSortLabel-icon{
              opacity: 0.5 !important;
            }
          }
          &.MuiTableSortLabel-active{
            color: #496ac5;
            .MuiTableSortLabel-icon{
              color: #496ac5 !important;
              opacity: 1 !important;
            }
          }
        }
      }
    }
    tbody{
      tr{
        &:hover{
          background: #e6ebfb!important;
        }
        &:nth-child(odd){
          background: #fff;
        }
        &:nth-child(even){
          background: #f6f8ff;
        }
        &.Mui-selected{
          background: rgba(245, 0, 87, 0.08);
        }
        td{
          padding: 6px 10px;
          word-break: break-word;
          font-size: 15px;
        }
      }
    }
  }
  .deployment-info{
    background: #fff1b6;
    padding: 5px;
    border: 1px solid #a78701;
    border-radius: 4px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span{
      color: #a78701;
      font-size: 12px;
      font-family: $ibmplexSemiBold;
      h6{
        color:#478ddc;
        font-size: 12px;
        display: inline;
      }
    }
    img{
      float: right;
      cursor: pointer;
      // color: #ff0000;
      margin-left: 5px;
    }
  }
  
  .label-value-card{
    p{
      @include elementStyles($size: 14px, $color: #a0a0a0, $fontFamily: $ibmplexRegular);
      margin-bottom: 4px;
    }
    h6{
      @include elementStyles($size: 16px, $color: $blackColor, $fontFamily: $ibmplexRegular);
      word-break: break-word;
    }
  }
  .in-value {
    float: right;
    p {
      @include elementStyles($size: 14px, $color: rgba(0, 0, 0, 0.54), $fontFamily: $ibmplexRegular);
      margin-bottom: 4px;
    }
    h6{
      @include elementStyles($size: 0.875rem, $color: rgba(0, 0, 0, 0.87), $fontFamily: $ibmplexRegular);
      word-break: break-word;
    }
  }
  .empty-collection-ui{
    padding: 20px 0;
    text-align: center;
    clear: both;
    img{
      width: 320px;
      margin-bottom: 10px;
    }
    h4{
      @include elementStyles($size: 18px, $color: $blackColor, $fontFamily: $ibmplexRegular, $margin: 0 0 10px 0);
    }
    p{
      @include elementStyles($size: 14px, $color: #c4c4c4, $fontFamily: $ibmplexRegular);
      width: 540px;
      margin: 0 auto;
    }
  }
  .plt-filter-comp{
    padding: 10px 10px 6px 10px;
    border-radius: 0;
    min-height: 30px;
    position: relative;
    clear: both;
    .filter-icon{
      margin-right: 10px;
      position: relative;
      top: -4px;
    }
    .clear-all-filter{
      position: absolute;
      right: 10px;
      top: 8px;
      font-size: 12px;
      border: 1px solid #3f51b5;
      padding: 1px 4px;
      border-radius: 3px;
      cursor: pointer;
      color: #3f51b5;
    }
    .filter-applied{
      padding-right: 72px;
      ul{
        li{
          margin: 0 5px 5px 0;
          padding: 2px 5px 5px 10px;
          background: rgba(0,78,255, 0.2);
          border: 1px solid rgba(101,83,251,.35);
          border-radius: 14px;
          @include elementStyles($size: 12px, $color: #c4c4c4, $fontFamily: $ibmplexRegular);
          .filter-label{
            color: #0b1f8a;
            text-decoration: underline;
            display: inline-block;
            margin-right: 8px;
          }
          .filter-data{
            color: $blackColor;
            font-family: $ibmplexSemiBold;
          }
          .remove-filter{
            cursor: pointer;
            padding-left: 8px;
            position: relative;
            top: 2px;
            svg{
              font-size: 16px;
              color: #ea6363;
            }
          }
        }

      }
    }
  }
  .plt-filter-footer{
    position: fixed;
    bottom: 0px;
    background: #fff;
    border-top: 1px solid #d7d7d7;
    z-index: 99;
    width: 100%;
    padding: 15px 30px;
    button{
      margin-right: 10px;
    }
  }
  .plt-filter-header{
    position: fixed;
    top: 0;
    width: 100%;
    padding: 16px 30px;
    border-bottom: 1px solid #d7d7d7;
    background: #e4e4e4;
    z-index: 99;
    h3{
      @include elementStyles($size: 18px, $color: #000000, $fontFamily: $ibmplexRegular);
    }
  }
  .plt-filter-body{
    padding: 60px 30px 80px 30px;
    width: 300px;
    .date-comp{
      margin-top: 15px;
      h4{
        // @include elementStyles($size: 16px, $color: rgba(0, 0, 0, 0.54), $fontFamily: $ibmplexRegular);
        font-size: 16px;
        color: rgba(0, 0, 0, 0.54);
        padding: 0;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
      }
    }
  }
  .page-loader{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 9999;
    background: rgba(0,0,0,0.1);
    overflow: hidden;
    cursor: not-allowed;
    span{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      img{
        width: 100px;
      }
    }
  }
  .faqs-list{
    h2.title{
      @include elementStyles($size: 16px, $color: #bd10e0, $fontFamily: $ibmplexRegular, $margin:15px 0 10px 0);
    }
    .content{
      @include elementStyles($size: 14px, $color: #000000, $fontFamily: $ibmplexRegular);
    }
    .MuiExpansionPanelDetails-root {
      display: flex;
      padding: 8px 34px 16px;
    }
  }
  .whats-new-timeline{
    ul{
      li{
        padding: 15px 0;
        border-bottom: 1px solid #d7d7d7;
        span{
          @include elementStyles($size: 13px, $color: #c4c4c4, $fontFamily: $ibmplexLight);
        }
        h4{
          @include elementStyles($size: 16px, $color: #000000, $fontFamily: $ibmplexRegular, $margin:0 0 16px 0);
        }
        .content{

        }
      }
    }
  }

  .delete-record-modal{
    text-align: center;
    padding: 30px;
    h4{
      @include elementStyles($size: 20px, $color: #000000, $fontFamily: $ibmplexRegular, $margin: 0 0 40px 0);
    }
    .actions-cta{
      button{
        margin-left: 10px;
        &.first-child{
          margin-left: 0;
        }
      }
    }
  }
  .termsHd{
    .auth-section{
      min-height: auto;
    }
    .termsAndCondition {
      padding: 30px 15px 60px;
      margin: 0 auto;
      background-color: #ffffff;
      h2 {
          font-family: $ibmplexSemiBold;
          font-size: 14px;
          color: #000;
          margin: 40px 0 10px;
          &.intro-heading{
            margin-top:0;
          }
      }
      p, span {
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #7a7a7a;
      }
      li{
        font-family: $ibmplexRegular;
        font-size: 14px;
        color: #7a7a7a;
      }
    }
  }
    .map-section{
      .autocomplete-dropdown-container{
        // border-radius: 4px;
        box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
        .suggestion-item, .suggestion-item--active{
          span{
            display: inline-block;
            padding: 5px;
            font-size: 14px;
            margin: 0 5px 5px;
          }
        }
      }
    }
  .autocomplete-dropdown-container{
    // border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
    .suggestion-item, .suggestion-item--active{
      span{
        display: inline-block;
        padding: 5px;
        font-size: 14px;
        margin: 0 5px 5px;
      }
    }
  }
  .siq_bR{
    bottom: 10px!important;
    left: 7px!important;
    .zsiq_float{
      .zsiq_cnt{
        left: 70px!important;
        &::after{
          left: -205px!important;
          box-shadow: none!important;
        }
      }
    }
  }
  .zls-sptwndw{
    left: 0!important;
  }
}


@media only screen and (max-width: 600px){
  .MuiChip-sizeSmall{
    margin: 3px;
  }
  .apps-title-cta-list{
    margin-bottom: 20px;
    overflow: hidden;
  }
  .MuiDialog-paper{
    .MuiDialogActions-root{
      button{
        font-size: 11px;
      }
    }
  }
  .location-search-input{
    position: unset !important;
    right: auto !important;
    top: auto !important;
    width: 93% !important;
    margin-bottom: 10px;
  }
  .map-section{
    margin-bottom: 20px;
    width: 100%;
    .autocomplete-dropdown-container{
      position: unset !important;
      right: auto !important;
      top: auto !important;
      width: 93% !important;
    }
  }
  .makeStyles-root-5, .makeStyles-root-1{
    z-index: 999;
  }
  .mui-styled-table tbody tr td{
    word-break: normal!important;
  }
  .error-page{
    padding: 0 15px;
    h2{
      font-size: 24px;
    }
    p{
      font-size: 16px;
      margin-top: 20px;
    }
    .error-image{
      margin: 20px 0;
      width: 200px;
    }
    .go-back-button{
      padding:6px;
    }
  }
}
@media only screen and (min-width: 601px) and (max-width: 960px){
  .mui-styled-table tbody tr td{
    word-break: normal!important;
    white-space: nowrap;
  }
  .makeStyles-root-1{
    z-index: 999;
  }
  .makeStyles-root-5{
    z-index: 999;
  }
  .MuiChip-sizeSmall{
    margin: 3px;
  }
}
